import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/index",
    name: "首页",
    meta: {
      title: "聚积宝"
    },
    component: () => {
      return import(/* webpackChunkName: "index" */ "../views/index.vue");
    }
  },
  {
    path: "/product",
    name: "decIndex",
    redirect: "/product/kuajie",
    component: () => {
      return import(/* webpackChunkName: "index" */ "../views/Product.vue");
    },
    children: [
      {
        path: "kuajie",
        name: "跨界连接服务商",
        meta: {
          title: "跨界连接服务商"
        },
        component: () => {
          return import(
            /* webpackChunkName: "index" */ "../views/ProductKuajie.vue"
          );
        }
      },
      {
        path: "huiyuan",
        name: "会员权益系统",
        meta: {
          title: "会员权益系统"
        },
        component: () => {
          return import(
            /* webpackChunkName: "index" */ "../views/ProductHuiyuan.vue"
          );
        }
      },
      {
        path: "renli",
        name: "人力saas",
        meta: {
          title: "人力saas"
        },
        component: () => {
          return import(
            /* webpackChunkName: "index" */ "../views/ProductRenli.vue"
          );
        }
      }
    ]
  },
  {
    path: "/company",
    name: "company",
    redirect: "/company/about",
    component: () => {
      return import(/* webpackChunkName: "company" */ "../views/About.vue");
    },
    children: [
      {
        path: "/company/about",
        name: "公司简介",
        meta: {
          title: "公司简介"
        },
        component: () => {
          return import("../views/AboutUsIntro");
        }
      },
      {
        path: "/company/join",
        name: "加入公司",
        meta: {
          title: "加入公司"
        },
        component: () => {
          return import("../views/AboutUsJoin");
        }
      },
      {
        path: "/company/news",
        name: "公司动态",
        meta: {
          title: "公司动态"
        },
        component: () => {
          return import("../views/AboutUsNews");
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: "/",
  routes,
  scrollBehavior() {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
