<template>
  <header class="head-wrap">
    <div class="head-cont">
      <div class="head-logo"></div>
      <div class="head-nav">
        <router-link to="/index"><div class="nav-item">首页</div></router-link>
        <router-link to="/product"
          ><div class="nav-item product-item">
            产品
            <div class="sub-nav product-nav">
              <router-link to="/product/kuajie"
                ><div class="sub-nav-item cross">
                  跨界连接服务商
                </div></router-link
              >
              <router-link to="/product/huiyuan"
                ><div class="sub-nav-item member">
                  会员权益系统
                </div></router-link
              >
              <router-link to="/product/renli"
                ><div class="sub-nav-item invite">人力saas</div></router-link
              >
            </div>
          </div></router-link
        >
        <router-link to="/company"
          ><div class="nav-item  about-item">
            关于我们
            <div class="sub-nav about-nav">
              <router-link to="/company/about"
                ><div class="sub-nav-item intro">公司简介</div></router-link
              >
              <router-link to="/company/join"
                ><div class="sub-nav-item join">加入公司</div></router-link
              >
              <!--              <router-link to="/company/news"-->
              <!--                ><div class="sub-nav-item news">公司动态</div></router-link-->
              <!--              >-->
            </div>
          </div></router-link
        >
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "header",
  methods: {}
};
</script>

<style lang="scss" scoped>
.head-wrap {
  width: 100%;
  height: 77px;
  .head-nav {
    display: flex;
    margin: 10px 0 0 243px;
    font-size: 16px;
    color: #212121;
    .nav-item {
      padding-right: 77px;
      color: #212121;
      position: relative;
      padding-bottom: 20px;
    }
    .sub-nav {
      display: none;
      position: absolute;
      top: 35px;
      width: 156px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 7px 3px 32px 0px rgba(120, 28, 19, 0.16);
      box-sizing: border-box;
      padding: 14px 10px;
      a {
        .sub-nav-item {
          line-height: 24px;
          font-size: 14px;
          margin-bottom: 12px;
          padding-left: 32px;
          background-repeat: no-repeat;
          background-size: 24px 24px;
          color: #212121;
        }
        &:last-child {
          .sub-nav-item {
            margin-bottom: 0;
          }
        }
      }
      .cross {
        background-image: url("../assets/images/common/kuajie.png");
      }
      .member {
        background-image: url("../assets/images/common/huiyuan.png");
      }
      .invite {
        background-image: url("../assets/images/common/renli.png");
      }
      .intro {
        background-image: url("../assets/images/common/gsjj.png");
      }
      .join {
        background-image: url("../assets/images/common/renli.png");
      }
      .news {
        background-image: url("../assets/images/common/gsdt.png");
      }
    }
    .product-nav {
      left: -60px;
    }
    .about-nav {
      left: -60px;
    }
    .product-item,
    .about-item {
      background-image: url("../assets/images/common/nav-arrow.png");
      background-repeat: no-repeat;
      background-size: 9px 5px;
      background-position: 40px 6px;
    }
    .product-item:hover,
    .about-item:hover {
      .sub-nav {
        display: block;
      }
    }
    .about-item {
      background-position: 75px 6px;
    }
    .router-link-active {
      .nav-item {
        color: #e91c28;
        font-weight: bold;
      }
      .router-link-active {
        .sub-nav-item {
          color: #e91c28;
          font-weight: bold;
        }
      }
      .product-item,
      .about-item {
        background-image: url("../assets/images/common/nav-arrow-active.png");
      }
    }
  }
  .head-cont {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
  }
  .head-logo {
    width: 129px;
    height: 38px;
    background-image: url("../assets/images/index/new-brand.png");
    background-repeat: no-repeat;
    background-size: 129px auto;
    margin-top: 2px;
  }
}
</style>
