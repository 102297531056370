<template>
  <div class="footer-wrap">
    <div class="footer">
      <div class="footer-item">
        <div class="footer-title">产品</div>
        <div
          class="footer-content footer-link"
          @click="goto('/product/kuajie')"
        >
          跨界连接服务
        </div>
        <div
          class="footer-content footer-link"
          @click="goto('/product/huiyuan')"
        >
          会员权益系统
        </div>
        <div class="footer-content footer-link" @click="goto('/product/renli')">
          人力SaaS服务
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-title">公司介绍</div>
        <div class="footer-content footer-link" @click="goto('/company/about')">
          公司简介
        </div>
        <!--        <div class="footer-content footer-link" @click="goto('/company/news')">-->
        <!--          公司动态-->
        <!--        </div>-->
        <div class="footer-content footer-link" @click="goto('/company/join')">
          加入我们
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-title">联系我们</div>
        <div class="footer-content">联系电话：400-668-0091</div>
        <div class="footer-content">
          公司地址：浙江省杭州市滨江区江二路57号人工智能产业园
        </div>
      </div>
    </div>
    <div class="footer-version">
      <span class="version-text"
        >© 2015-2017杭州聚积宝网络科技有限公司版权所有</span
      >
      <img
        class="police-icon"
        src="https://c1.51jujibao.com/static/imgzkd/footer-police.jpg"
      />
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=330108000234629"
        >浙公安备330108000234629号</a
      >
      <a class="footer-icp" href="https://beian.miit.gov.cn/#/Integrated/index"
        >浙ICP备15034459号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
  methods: {
    goto(url) {
      this.$router.push(url);
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-wrap {
  width: 100%;
  height: 455px;
  background: #0d1020;
  .footer {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 100px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.62);
    .footer-item {
      margin-right: 167px;
      color: #fff;
      .footer-title {
        font-size: 24px;
        margin-bottom: 29px;
      }
      .footer-content {
        font-size: 16px;
        margin-bottom: 19px;
      }
      .footer-link {
        cursor: pointer;
      }
    }
  }
  .footer-version {
    display: flex;
    color: #c1c1c1;
    width: 1200px;
    margin: 0 auto;
    justify-content: center;
    padding-top: 33px;
    a {
      text-decoration: none;
      color: #c1c1c1;
    }
    .police-icon {
      padding-left: 60px;
      padding-right: 10px;
      color: #c1c1c1;
    }
    .footer-icp {
      padding-left: 10px;
    }
  }
}
</style>
